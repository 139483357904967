@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

.contact_home {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 100px;
  background: url(../../../images/Contact_img.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: contain;
  padding: 70px;
}

.contact_container {
  max-width: 1400px;
  padding: 50px 20px;
  margin: 0 auto;
  height: 100%;
}

.contact_container .contact_row {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Open Sans", sans-serif;
}

.contact_container .contact_row .col50 {
  position: relative;
  width: 40%;
}

.contact_container .contact_row .white h1 {
  color: #12a9e7;
  font-size: 32px;
  letter-spacing: 1px;
  font-weight: 600;
  text-transform: uppercase;
}

.contact_container .contact_row .white p {
  margin-top: 30px;
  font-size: 18px;
  letter-spacing: 1px;
  line-height: 32px;
  color: #3e3b49;
}

.contact_container .contact_row .white .contact_button {
  margin-top: 30px;
  background: #6a4bfd;
  max-width: 180px;
  text-align: center;
  text-transform: uppercase;
}

.contact_container .contact_row .purple h1 {
  color: #fff;
  font-size: 32px;
  letter-spacing: 1px;
  font-weight: 600;
  text-transform: uppercase;
}

.contact_container .contact_row .purple p {
  margin-top: 30px;
  font-size: 18px;
  letter-spacing: 1px;
  line-height: 32px;
  color: #fff;
}

.contact_container .contact_row .purple .contact_button {
  margin-top: 40px;
  background: #fff;
  max-width: 180px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
}

.contact_container .contact_row .purple .text {
  color: #6a4bfd;
}

.contact_button_link {
  text-decoration: none;
}

@media screen and (max-width: 900px) {
  .contact_home {
    padding: 0;
    background: none;
    background: red;
  }

  .contact_container {
    padding: 0;
    background: #fff;
  }

  .contact_container .contact_row {
    flex-direction: column;
  }

  .contact_container .contact_row .col50 {
    width: 100%;
    text-align: center;
  }

  .contact_container .contact_row .white {
    padding: 80px 0;
    background: #f6f6f6;
  }

  .contact_container .contact_row .purple {
    padding: 80px 0;
    background: #6a4bfd;
  }

  .contact_container .contact_row .white h1,
  .contact_container .contact_row .purple h1 {
    font-size: 28px;
  }

  .contact_container .contact_row .white p,
  .contact_container .contact_row .purple p {
    font-size: 16px;
  }

  .contact_container .contact_row .white .contact_button,
  .contact_container .contact_row .purple .contact_button {
    margin: 0 auto;
  }
}
