@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&family=Roboto+Slab:wght@400;500;600;700&display=swap");

.Business_Hero {
  position: relative;
  width: 100%;
  height: 100%;
  background: url(../../../images/Business_hero_bg.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: contain;
  padding: 130px;
}

.Business_Hero_container {
  max-width: 500px;
  background: #eeeeee93;
  border: 1px solid #999;
  border-radius: 30px;
  padding: 40px;
  margin-left: 120px;
}

.Business_Hero_container .text {
  padding: 20px 30px;
}

.Business_Hero_container .text h1 {
  font-family: "Roboto slab", sans-serif;
  text-align: center;
  font-size: 35px;
  line-height: 44px;
}

.Business_Hero_container .text p {
  font-family: "Poppins", sans-serif;
  margin-top: 30px;
  letter-spacing: 1px;
}

@media screen and (max-width: 900px) {
  .Business_Hero {
    padding: 60px 40px;
  }

  .Business_Hero_container {
    margin: 0 auto;
  }
}

@media screen and (max-width: 600px) {
  .Business_Hero_container {
    padding: 0;
  }

  .Business_Hero_container .text {
    padding: 20px;
    text-align: center;
  }

  .Business_Hero_container .text h1 {
    margin-top: 30px;
  }

  .Business_Hero_container .text p {
    font-size: 14px;
    margin-top: 20px;
  }
}
