@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

.home_quality {
  position: relative;
  width: 100%;
  height: 100%;
  background: url(../../../images/QualitySection.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: contain;
  padding: 200px;
}

.quality_container {
  margin: auto 0;
  padding: 50px;
  max-width: 1000px;
  height: 100%;
}

.quality_text {
  color: #fff;
  font-family: "Open Sans", sans-serif;
}

.quality_text h1 {
  font-size: 40px;
  letter-spacing: 1px;
  font-weight: 600;
  text-transform: uppercase;
}

.quality_text .quality_paragraph {
  margin-top: 50px;
}

.quality_text .quality_paragraph p {
  font-weight: 400;
  font-size: 20px;
  margin-top: 20px;
}

.button_quality {
  max-width: 180px;
  text-align: center;
  background: #1f367a;
  margin-top: 60px;
}

@media screen and (max-width: 900px) {
  .home_quality {
    padding: 40px 0;
  }

  .quality_container {
    padding: 40px 20px;
  }

  .quality_text h1 {
    font-size: 28px;
  }

  .quality_text .quality_paragraph {
    margin-top: 30px;
  }

  .quality_text .quality_paragraph p {
    font-size: 16px;
  }

  .quality_text {
    text-align: center;
  }

  .button_quality {
    margin: 30px auto;
  }
}
