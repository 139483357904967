@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&family=Roboto+Slab:wght@400;500;600;700&display=swap");

.PeopleSection1 {
  position: relative;
  width: 100%;
  height: 100%;
  background: url(../../../images/About_Hero_bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: contain;
  padding: 100px;
}

.PeopleSection1_container {
  max-width: 600px;
  margin: 0 auto;
  background: #fff;
  border: 1px solid #999;
  border-radius: 50px;
  padding: 80px;
}

.PeopleSection_text h1 {
  margin-bottom: 30px;
  font-family: "Roboto slab", sans-serif;
  font-size: 50px;
  letter-spacing: 0.5px;
  text-align: center;
}

.PeopleSection_text p {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 17px;
  letter-spacing: 0.5px;
}

@media screen and (max-width: 900px) {
  .PeopleSection1 {
    padding: 50px;
  }

  .PeopleSection1_container {
    padding: 40px;
  }
}

@media screen and (max-width: 600px) {
  .PeopleSection1 {
    padding: 20px;
  }

  .PeopleSection1_container {
    padding: 20px;
  }

  .PeopleSection_text h1 {
    margin-top: 30px;
    font-size: 40px;
  }

  .PeopleSection_text p {
    font-size: 14px;
  }
}
