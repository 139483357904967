@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

.Footer {
  position: relative;
  max-width: 100%;
  height: 100%;
  background: url(../../images/bg-footer.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: contain;
  font-family: "Open Sans", sans-serif;
}

.Footer .Footer_container {
  position: relative;
  margin: 0 auto;
  color: #fff;
  padding: 20px;
  max-width: 1600px;
  height: 100%;
}

.footer_logo {
  margin-top: 40px;
  margin-left: 100px;
  position: relative;
  width: 293px;
  height: 61px;
}

.Footer .address_container .address h2 {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
}

.Footer .address_container .address p {
  font-size: 15px;
  letter-spacing: 1px;
  margin-top: 20px;
}

.Footer .address_container .follow {
  margin-top: 30px;
}

.Footer .address_container .follow h2 {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;
}

.Footer .address_container .follow .footer_icon {
  font-size: 28px;
  margin-left: 17px;
  margin-bottom: 4px;
}

.Footer_container .quick_links h2 {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
}

.Footer_container .quick_links ul {
  margin-top: 10px;
}

.Footer_container .quick_links ul li {
  list-style: none;
}

.Footer_container .quick_links ul li::before {
  content: "";
  display: inline-block;
  height: 30px;
  width: 30px;
  background-image: url(../../images/footer-icon.svg);
  margin-right: 10px;
}

.part1 {
  position: relative;
  max-width: 1400px;
  margin: 14px auto;
  padding: 10px 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}

.address_container,
.quick_links,
.map {
  position: relative;
  width: 30%;
  margin-top: 20px;
  height: 300px;
}

.Footer_container .map {
  overflow: hidden;
}

.Footer_container .map iframe {
  border-radius: 20px;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.Footer_container hr {
  margin-top: 20px;
  height: 3px;
}

.Footer_container .copyright {
  max-width: 800px;
  text-align: center;
  margin: 0 auto;
  margin-top: 30px;
}

.Footer_container .Footer_Navlink {
  text-decoration: none;
  color: #fff;
  font-weight: 600;
}

.Footer_container .Footer_Navlink:hover {
  transition: 0.2s ease-in;
  color: #9c1ee1;
}

@media screen and (max-width: 1132px) {
  .address_container,
  .quick_links,
  .map {
    width: 50%;
    justify-content: center;
  }
}

@media screen and (max-width: 768px) {
  .Footer {
    padding: 0;
  }

  .Footer_container .part1 .map {
    width: 100%;
  }

  .Footer .address_container .follow h2 {
    font-size: 16px;
  }

  .Footer .address_container .follow .footer_icon {
    font-size: 23px;
    margin-left: 5px;
  }

  .footer_logo {
    margin-left: 0;
    width: 250px;
    height: 60px;
  }

  .footer_logo img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .Footer_container .quick_links h2 {
    text-align: center;
  }
}
