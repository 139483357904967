@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&family=Roboto+Slab:wght@400;500;600;700&display=swap");

.about_section2 {
  width: 100%;
  height: 100%;
  padding: 50px;
  background: #fff;
}

.about_section2_container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 20px;
}

.about_section2_container .box {
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 25px;
  height: 100%;
  background: #c7dbf4;
}

.about_section2_container .box .column {
  display: flex;
}

.about_section2_container .box .col50 {
  position: relative;
  width: 50%;
}

.about_section2_container .box .col50 .imgbox {
  position: relative;
  max-width: 700px;
  height: 360px;
  overflow: hidden;
}

.about_section2_container .box .col50 .About_text {
  padding: 20px;
}

.about_section2_container .box .col50 .About_text h1 {
  color: #3d2019;
  font-family: "Roboto Slab", sans-serif;
  font-size: 50px;
  font-weight: 400;
  margin-top: 20px;
}

.about_section2_container .box .col50 .About_text p {
  color: #3d2019;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 300;
  margin-top: 20px;
}

.about_section2_container .box .imgbox img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about_section2_container .box .about_section2_button {
  margin-top: 30px;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  padding: 3px;
  max-width: 280px;
  background: #606eb2;
}

.about_section2_container .box .about_section2_button .text {
  margin-left: 8px;
  letter-spacing: 1px;
}

@media screen and (max-width: 1200px) {
  .about_section2 {
    padding: 30px;
  }

  .about_section2_container {
    padding: 10px;
  }
}

@media screen and (max-width: 900px) {
  .about_section2_container .box .col50 .About_text h1 {
    font-size: 40px;
    margin-top: 30px;
  }
  .about_section2_container .box .col50 .About_text p {
    font-size: 15px;
    margin-top: 20px;
  }
}

@media screen and (max-width: 700px) {
  .about_section2 {
    width: 100%;
    height: 100%;
    padding: 50px;
    background: none;
  }

  .about_section2_container .box .col50 .About_text {
    text-align: center;
    padding: 10px;
  }

  .about_section2_container .box .col50 .imgbox {
    height: 260px;
  }
  .about_section2 {
    padding: 0;
  }

  .about_section2_container {
    padding: 10px;
  }

  .about_section2_container .box .column {
    display: flex;
    flex-direction: column;
  }

  .about_section2_container .box .col50 {
    width: 100%;
  }

  .about_section2_container .box .about_section2_button {
    font-size: 12px;
    margin: 0 auto;
    margin-bottom: 30px;
    padding: 0;
    max-width: 250px;
    background: #606eb2;
  }

  .about_section2_container .box .about_section2_button .text {
    margin-left: 8px;
    letter-spacing: 1px;
  }
}
