.production_section2 {
  width: 100%;
  height: 100%;
}

.product_container {
  padding: 3rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
}

.product_item {
  outline: 2px solid #999;
  object-fit: cover;
}

img {
  width: 100%;
}

@media screen and (max-width: 900px) {
  .product_container {
    grid-template-columns: 1fr;
  }
}
