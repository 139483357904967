@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&family=Roboto+Slab:wght@400;500;600;700&display=swap");

.Manufacturing_Section1 {
  position: relative;
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 100px;
}

.Manufacturing_Section1_container {
  max-width: 600px;
  border-radius: 40px;
  margin: 0 auto;
  background: #eee;
  padding: 80px;
}

.Manufacturing_Section1_text p {
  font-size: 18px;
  letter-spacing: 0.4px;
  font-family: "Poppins", sans-serif;
}

@media screen and (max-width: 900px) {
  .Manufacturing_Section1 {
    padding: 50px;
  }

  .Manufacturing_Section1_container {
    margin: 0 auto;
    padding: 60px;
  }
}

@media screen and (max-width: 600px) {
  .Manufacturing_Section1 {
    padding: 20px;
  }
  .Manufacturing_Section1_container {
    padding: 40px 30px;
  }

  .Manufacturing_Section1_text p {
    font-size: 16px;
    letter-spacing: 0.2px;
  }
}
