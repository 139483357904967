@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&family=Roboto+Slab:wght@400;500;600;700&display=swap");

.People_Hero {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 90px;
  background: url(../../../images/People_Hero.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: contain;
}

.People_container {
  max-width: 500px;
  background: #eeeeeee8;
  border: 1px solid #333;
  margin-left: 120px;
  padding: 60px 40px;
  border-radius: 40px;
}

.People_text {
  padding: 10px;
}

.People_text h1 {
  text-align: center;
  font-family: "Roboto Slab", sans-serif;
  font-size: 45px;
}

.People_text ul {
  margin-top: 27px;
  list-style: square;
}

.People_text ul li {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 30px;
  color: #000;
  margin-top: 15px;
  letter-spacing: 0.8px;
}

@media screen and (max-width: 900px) {
  .People_Hero {
    padding: 40px 20px;
  }

  .People_container {
    margin: 0 auto;
    padding: 40px 10px;
  }

  .People_text h1 {
    font-size: 30px;
  }

  .People_text ul {
    margin-top: 15px;
  }

  .People_text ul li {
    margin-top: 8px;
  }
}

@media screen and (max-width: 600px) {
  .People_container {
    padding: 40px 0;
  }

  .People_text {
    padding: 0;
  }

  .People_text ul li {
    line-height: 25px;
    letter-spacing: 0.4px;
  }
}
