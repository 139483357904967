@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

.philosophy_home {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 100px;
  background: #e3f4fc;
}

.philosophy_container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 130px 20px;
}

.philosophy_row {
  display: flex;
  justify-content: space-around;
}

.philosophy_row .philosophy_text {
  position: relative;
  width: 50%;
  font-family: "Open Sans", sans-serif;
}

.philosophy_row .philosophy_text h1 {
  color: #12a9e7;
  font-size: 51px;
  text-transform: uppercase;
}

.philosophy_row .philosophy_text p {
  font-size: 22px;
  color: #5e5a72;
  margin-top: 40px;
  letter-spacing: 1px;
}

.philosophy_row .philosophy_imgbox {
  position: relative;
  width: 50%;
  position: relative;
  height: 300px;
  overflow: hidden;
}

.philosophy_row .philosophy_imgbox img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media screen and (max-width: 900px) {
  .philosophy_home {
    padding: 40px 10px;
  }

  .philosophy_container {
    padding: 20px;
  }

  .philosophy_row {
    flex-direction: column;
  }

  .philosophy_row .philosophy_text {
    width: 100%;
    text-align: center;
  }

  .philosophy_row .philosophy_text h1 {
    font-size: 35px;
  }

  .philosophy_row .philosophy_text p {
    font-size: 16px;
  }

  .philosophy_row .philosophy_imgbox {
    width: 100%;
  }
}
