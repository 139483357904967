@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&family=Roboto+Slab:wght@400;500;600;700&display=swap");

.ProductsSection1 {
  position: relative;
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 60px 100px;
}

.ProductsSection1_container {
  max-width: 1100px;
  margin: 0 auto;
}

.Product_Section1_box {
  position: relative;
  background: #999;
  width: 100%;
  height: 100%;
  margin-top: 40px;
  margin-bottom: 40px;
}

.Product_Section1_row {
  display: flex;
}

.Product_Section1_col50 {
  width: 50%;
}

.Product_section1_imgbox {
  position: relative;
  max-width: 850px;
  height: 380px;
  overflow: hidden;
}

.Product_section1_imgbox img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Product_Section1_col50_text {
  padding: 40px;
}

.box1 {
  background: rgb(233, 227, 224);
}

.box1 .Product_Section1_col50_text h1 {
  color: #3d2019;
  font-family: "Roboto Slab", sans-serif;
  font-weight: 400;
  font-size: 45px;
  margin-top: 20px;
  line-height: 52px;
}

.box2 .Product_Section1_col50_text h1 {
  color: #3d2019;
  font-family: "Roboto Slab", sans-serif;
  font-weight: 400;
  font-size: 45px;
  margin-top: 20px;
  line-height: 52px;
}

.box1 .Product_Section1_col50_text p {
  color: #3d2019;
  font-family: "Poppins", sans-serif;
  margin-top: 20px;
  font-weight: 300;
  letter-spacing: 0.3px;
  font-size: 16px;
  line-height: 24px;
}

.box2 .Product_Section1_col50_text p {
  color: #3d2019;
  font-family: "Poppins", sans-serif;
  margin-top: 20px;
  font-weight: 300;
  letter-spacing: 0.3px;
  font-size: 16px;
  line-height: 24px;
}

.box1 .Products_section1_button {
  max-width: 400px;
  margin-top: 30px;
  background: #3d2019;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 0.5px;
  text-align: center;
  font-weight: 500;
}
.box2 .Products_section1_button {
  max-width: 400px;
  margin-top: 30px;
  background: #3d2019;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 0.5px;
  text-align: center;
  font-weight: 500;
}

.box2 {
  background: #d3e9db;
}

.box2 .Products_section1_button {
  background: #00503a;
}

@media screen and (max-width: 1134px) {
  .ProductsSection1 {
    padding: 60px 40px;
  }

  .Product_section1_imgbox {
    height: 400px;
  }
}

@media screen and (max-width: 900px) {
  .Product_Section1_row {
    flex-direction: column;
  }
  .Product_Section1_col50 {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .ProductsSection1 {
    padding: 40px 20px;
  }

  .Product_Section1_col50_text {
    padding: 20px;
  }

  .Product_Section1_box .Product_Section1_col50_text h1 {
    font-size: 30px;
  }

  .Product_Section1_box .Product_Section1_col50_text 0 {
    font-size: 14px;
  }

  .Product_Section1_box .Product_section1_imgbox {
    height: 300px;
  }
}
