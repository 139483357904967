@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap");

.carousel {
  position: relative;
  top: 2px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
}

.w-100 {
  width: 100%;
  max-height: 90vh !important;
}

.carousel .carousel-caption {
  position: absolute;
  top: 5vh;
  left: -60vw;
  color: #fff;
  font-family: "Poppins", sans-serif;
}

.carousel .carousel-caption h1 {
  font-weight: 700;
  animation: animate 2s linear;
}

@keyframes animate {
  from {
    margin-left: -500px;
  }

  to {
    margin-left: 0;
  }
}

@media screen and (max-width: 1454px) {
  .carousel .carousel-caption {
    top: 20vh;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@media (max-width: 900px) {
  .carousel {
    width: auto;
    height: auto;
  }

  .carousel .carousel-caption {
    top: 5vh;
    font-size: 20px;
  }
}
