@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&family=Roboto+Slab:wght@400;500;600;700&display=swap");

.AboutPage_Hero {
  position: relative;
  width: 100%;
  height: 100%;
  background: url(../../../images/About_Hero_bg1.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: contain;
  padding: 100px;
}

.AboutPage_Hero_container {
  max-width: 500px;
  padding: 80px 40px;
  border-radius: 30px;
  background: #eee;
  margin-left: 80px;
}

.AboutPage_Hero_text {
  padding: 20px;
}

.AboutPage_Hero_text h1 {
  font-family: "Roboto Slab", sans-serif;
  color: #3d2019;
  font-weight: 500;
}

.AboutPage_Hero_text p {
  font-family: "Poppins", sans-serif;
  color: #3d2019;
  margin-top: 25px;
  letter-spacing: 0.5px;
  line-height: 30px;
}

@media screen and (max-width: 900px) {
  .AboutPage_Hero {
    padding: 50px;
  }

  .AboutPage_Hero_container {
    margin: 0 auto;
  }
}

@media screen and (max-width: 600px) {
  .AboutPage_Hero {
    padding: 40px 20px;
  }

  .AboutPage_Hero_container {
    padding: 40px 20px;
  }
}

@media screen and (max-width: 400px) {
  .AboutPage_Hero_container {
    padding: 40px 10px;
  }
}
