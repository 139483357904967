@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&family=Roboto+Slab:wght@400;500;600;700&display=swap");

.Business_Section1 {
  position: relative;
  width: 100%;
  height: 100%;
  background: url(../../../images/Business_Section1_bg.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: contain;
  padding: 100px;
}

.Business_Section1_container {
  max-width: 700px;
  border-radius: 50px;
  background: #c0c0c050;
  border: 1px solid #000;
  margin: 0 auto;
  padding: 40px 20px;
}

.Business_Section1_text {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 40px;
  color: #fff;
}

.Business_Section1_text h1 {
  text-align: center;
  font-size: 70px;
  font-family: "Roboto Slab", sans-serif;
}

.Business_Section1_text ul {
  margin-top: 50px;
  list-style: square;
}

.Business_Section1_text ul li {
  margin-top: 20px;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  letter-spacing: 1px;
}

@media screen and (max-width: 900px) {
  .Business_Section1_text h1 {
    font-size: 50px;
  }

  .Business_Section1_text {
    padding: 10px;
  }
}

@media screen and (max-width: 700px) {
  .Business_Section1 {
    padding: 60px 20px;
  }

  .Business_Section1_container {
    padding: 20px;
  }

  .Business_Section1_text h1 {
    font-size: 35px;
  }

  .Business_Section1_text ul {
    margin-top: 20px;
  }

  .Business_Section1_text ul li {
    margin-top: 10px;
    font-size: 15px;
  }
}
