@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&family=Roboto+Slab:wght@400;500;600;700&display=swap");

.ResearchHero {
  position: relative;
  width: 100%;
  height: 100%;
  background: url(../../../images/R&D_bg.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: contain;
  padding: 120px;
}

.ResearchHero_container {
  max-width: 600px;
  background: rgb(238, 238, 238);
  border: 1px solid #999;
  border-radius: 30px;
  padding: 60px 40px;
}

.ResearchHero_text {
  padding: 20px;
}

.ResearchHero_text h1 {
  font-family: "Roboto Slab", sans-serif;
  font-size: 40px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 30px;
  color: #111;
}

.ResearchHero_text p {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  letter-spacing: 0.4px;
  margin-top: 10px;
}

@media screen and (max-width: 900px) {
  .ResearchHero {
    padding: 50px;
  }

  .ResearchHero_container {
    margin: 0 auto;
  }
}

@media screen and (max-width: 600px) {
  .ResearchHero {
    padding: 40px 20px;
  }

  .ResearchHero_container {
    padding: 10px;
  }

  .ResearchHero_text {
    padding: 10px;
  }

  .ResearchHero_text h1 {
    font-size: 35px;
    margin-bottom: 20px;
  }

  .ResearchHero_text p {
    font-size: 14px;
  }
}
